import React, { useEffect } from 'react'
import WebFont from 'webfontloader';
import Moment from 'react-moment';
// eslint-disable-next-line
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Container, Row, Card, Button, Col, Dropdown, Badge, Modal, Form, Spinner, Accordion, Alert, Tooltip, OverlayTrigger, ToggleButton, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileExcel, faFileWord, faCheck, faCoins, faEllipsisV, faCommentAlt, faArrowLeft, faPlusCircle, faDownload, faTrash, faTasks, faAddressBook, faPhoneAlt, faEnvelope, faMapMarkerAlt, faUser, faIdCard, faInfoCircle, faWallet, faShare, faRefresh, faFilePdf, faFile, faPen } from '@fortawesome/free-solid-svg-icons'
import NavBar from "../navbar/Navbar";
import { get, post, put, del } from '../../../controllers/endpoints.controller';
import { getUserRole, getUserToken, logout } from '../../../controllers/user.controller';
import { } from '@fortawesome/free-regular-svg-icons';
import { pn } from '../../../tools/chains';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { convert2Base64, processDate, timeDue, getFileIcon, timeDueWithBadge } from '../../../tools/tools';
import { params, colors } from '../../../tools/constants'
import moment from 'moment';
import PDFViewer from 'mgr-pdf-viewer-react/dist/mgr-pdf-viewer-react';

export default function DebtDetails() {

    const PAGE_TITLE = "GDD";
    const PAGE_ICON = faCoins;
    const [error, setError] = React.useState(<div></div>);
    const [tasks, setTasks] = React.useState([]);
    const [students, setStudents] = React.useState([]);
    const [debt, setDebt] = React.useState({});
    const [debtStatus, setDebtStatus] = React.useState(<div></div>);
    const [debtStatuses, setDebtStatuses] = React.useState([]);
    const [lastDueQty, setLastDueQty] = React.useState(0);
    const [customer, setCustomer] = React.useState(null);
    const [defendants, setDefendants] = React.useState([]);
    const [isArchived, setIsArchived] = React.useState(false);
    const [familyFather, setFamilyFather] = React.useState({});
    const [familyMother, setFamilyMother] = React.useState({});
    const [familyStudents, setFamilyStudents] = React.useState([]);
    const [familyDebtors, setFamilyDebtors] = React.useState([]);
    const [agreementColumns, setAgreementColumns] = React.useState([]);
    const [dueColumns, setDueColumns] = React.useState([]);
    const [dues, setDues] = React.useState([]);
    const [debtDetailsColumns, setDebtDetailsColumns] = React.useState([]);
    const [debtDetails, setDebtDetails] = React.useState([]);
    const [dueDates, setDueDates] = React.useState([]);
    const [proceedingActionsColumns, setProceedingActionsColumns] = React.useState([]);
    const [proceedingActions, setProceedingActions] = React.useState([]);
    const [customerContactsHeader, setCustomerContactsHeader] = React.useState([]);
    const [customerContacts, setCustomerContacts] = React.useState([]);
    const [selectedCustomerContacts, setSelectedCustomerContacts] = React.useState({});
    const [lawers, setLawers] = React.useState([]);
    const [selectedLawer, setSelectedLawer] = React.useState({});
    const [selectedDebtor, setSelectedDebtor] = React.useState({});
    const [preAgreementButton, setPreAgreementButton] = React.useState(<span></span>);
    const [showPreAgreementModal, setShowPreAgreementModal] = React.useState(false);
    const handleClosePreAgreementModal = () => { setShowPreAgreementModal(false); };
    const handleShowPreAgreementModal = () => {
        setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        setShowPreAgreementModal(true);
    };
    const [preAgreementDate, setPreAgreementDate] = React.useState(new Date());
    const [showNewActionModal, setShowNewActionModal] = React.useState(false);
    const [actionCategories, setActionCategories] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState({});
    const [selectedActionCategory, setSelectedActionCategory] = React.useState({});
    const handleCloseNewActionModal = () => { setShowNewActionModal(false); };
    const handleShowNewActionModal = () => {
        setNewActionDate(new Date())
        setError()
        setSelectedLawer("0")
        setSelectedActionCategory("0")
        setNewActionFile();
        getActionCategories();
        setShowNewActionModal(true);
    };

    const [newDebtDetailDate, setNewDebtDetailDate] = React.useState(new Date());
    const [showNewDebtDetailModal, setShowNewDebtDetailModal] = React.useState(false);
    const handleCloseNewDebtDetailModal = () => { setShowNewDebtDetailModal(false); };
    const handleShowNewDebtDetailModal = () => {
        setNewDebtDetailDate(new Date())
        setError()
        setShowNewDebtDetailModal(true);
    };

    const [editDebtDetail, setEditDebtDetail] = React.useState({});
    const [showEditDebtDetailModal, setShowEditDebtDetailModal] = React.useState(false);
    const handleCloseEditDebtDetailModal = () => { setShowEditDebtDetailModal(false); };
    const handleShowEditDebtDetailModal = (d) => {
        setNewDebtDetailDate(new Date(d.date))
        setEditDebtDetail(d)
        setError()
        setShowEditDebtDetailModal(true);
    };

    const [deleteDebtDetailId, setDeleteDebtDetailId] = React.useState(null);
    const [showDeleteDebtDetailModal, setShowDeleteDebtDetailModal] = React.useState(false);
    const handleCloseDeleteDebtDetailModal = () => { setShowDeleteDebtDetailModal(false); };
    const handleShowDeleteDebtDetailModal = (id) => {
        setDeleteDebtDetailId(id)
        setShowDeleteDebtDetailModal(true);
    };

    const [showDeleteActionModal, setShowDeleteActionModal] = React.useState(false);
    const [selectedActionId, setSelectedActionId] = React.useState("0");
    const [selectedActionDescription, setSelectedActionDescription] = React.useState("");
    const handleCloseDeleteActionModal = () => { setShowDeleteActionModal(false); };
    const handleShowDeleteActionModal = (id, description) => {
        setSelectedActionId(id)
        setSelectedActionDescription(description)
        setShowDeleteActionModal(true);
    }

    const [showDeleteProceedingModal, setShowDeleteProceedingModal] = React.useState(false);
    const handleCloseDeleteProceedingModal = () => { setShowDeleteProceedingModal(false); };
    const handleShowDeleteProceedingModal = () => {
        setShowDeleteProceedingModal(true);
    }

    const [showAgreementModal, setShowAgreementModal] = React.useState(false);
    const handleCloseAgreementModal = () => { setShowAgreementModal(false); };
    const handleShowAgreementModal = () => {
        if (customerContacts.length > 0) {
            setShowAgreementModal(true);
        } else {
            alert("No hay contactos para este cliente. Creá un contacto e intentá nuevamente")
        }
    }

    const [showCalcDuesModal, setShowCalcDuesModal] = React.useState(false);
    const handleCloseCalcDuesModal = () => { setShowCalcDuesModal(false); };
    const handleShowCalcDuesModal = () => {
        setShowCalcDuesModal(true);
    }

    const [showEditTaskModal, setShowEditTaskModal] = React.useState(false);
    const handleCloseEditTaskModal = () => { setShowEditTaskModal(false); };
    const handleShowEditTaskModal = (t) => {
        setEditTaskUser(t.userId)
        setEditTaskTitle(t.title)
        setEditTaskDescription(t.description)
        setEditTaskDate(t.dueDate == null ? null : new Date(t.dueDate))
        setEditTaskReminder(null)
        setShowEditTaskModal(true);
        setEditTaskId(t.id)
    };

    const [editTaskId, setEditTaskId] = React.useState(null);
    const [editTaskUser, setEditTaskUser] = React.useState(null);
    const [editTaskTitle, setEditTaskTitle] = React.useState(null);
    const [editTaskDescription, setEditTaskDescription] = React.useState(null);
    const [editTaskDate, setEditTaskDate] = React.useState(null);
    const [editTaskReminder, setEditTaskReminder] = React.useState(null);

    const handleEditTaskUser = (event) => {
        setEditTaskUser(event.target.value)
    };

    const handleEditTaskReminder = (event) => {
        setEditTaskReminder(event.target.value)
    };

    const handleEditTaskDate = (event) => {
        setEditTaskDate(event);
    };

    const [newActionFile, setNewActionFile] = React.useState(null);
    const [newActionDate, setNewActionDate] = React.useState(new Date());
    const [showNewTaskModal, setShowNewTaskModal] = React.useState(false);
    const [reminderOptions, setReminderOptions] = React.useState([]);
    const [newTaskDate, setNewTaskDate] = React.useState(null);
    const [newTaskReminder, setNewTaskReminder] = React.useState({});
    const [newTaskUser, setNewTaskUser] = React.useState({});
    const [newTaskNotificationChecked, setNewTaskNotificationChecked] = React.useState(false);
    const handleCloseNewTaskModal = () => { setShowNewTaskModal(false); };
    const handleShowNewTaskModal = () => {
        setShowNewTaskModal(true);
    };

    const handlePreAgreementDate = (event) => {
        setPreAgreementDate(event);
    };

    const [showChangeModuleModal, setShowChangeModuleModal] = React.useState(false);
    const [selectedModule, setSelectedModule] = React.useState("");
    const handleCloseChangeModuleModal = () => { setShowChangeModuleModal(false); };
    const handleShowChangeModuleModal = () => {
        setShowChangeModuleModal(true);
    };

    const [showChangeStatusModal, setShowChangeStatusModal] = React.useState(false);
    const handleCloseChangeStatusModal = () => { setShowChangeStatusModal(false); };
    const handleShowChangeStatusModal = () => {
        getAllDebtStatus();
        setShowChangeStatusModal(true);
    };

    const handleSelectedModule = (event) => {
        setSelectedModule(event.target.value);
    };

    const [showDeletePaymentModal, setShowDeletePaymentModal] = React.useState(false);
    const [selectedPaymentId, setSelectedPaymentId] = React.useState("");
    const handleCloseDeletePaymentModal = () => { setShowDeletePaymentModal(false); };
    const handleShowDeletePaymentModal = (id) => {
        setSelectedPaymentId(id)
        setShowDeletePaymentModal(true);
    };

    const handlePreAgreementApoderadoLegalNombre = (event) => {
        let dni = "";
        for (let cc of customerContacts) {
            if (event.target.value == cc.id) {
                setSelectedCustomerContacts(cc);
                for (let cd of cc.ContactData) {
                    if (cd.param == "DNIN") {
                        dni = cd.data;
                    }
                }
            }
        }
        document.getElementById('preagreementApoderadoLegalDNI').value = dni;
    };

    const [paymentsColumns, setPaymentsColumns] = React.useState([]);
    const [payments, setPayments] = React.useState([]);
    const [canPayOff, setCanPayOff] = React.useState(false);
    const [pendingPayOff, setPendingPayOff] = React.useState(0.00);

    const [showNewPaymentModal, setShowNewPaymentModal] = React.useState(false);
    const handleCloseNewPaymentModal = () => { setShowNewPaymentModal(false); };
    const handleShowNewPaymentModal = () => {
        setError()
        setShowNewPaymentModal(true);
    };
    const [newPaymentDate, setNewPaymentDate] = React.useState(new Date());

    const [showConfirmUpdateInterestsModal, setShowConfirmUpdateInterestsModal] = React.useState(false);
    const handleCloseConfirmUpdateInterestsModal = () => { setShowConfirmUpdateInterestsModal(false); };
    const handleShowConfirmUpdateInterestsModal = () => {
        setShowConfirmUpdateInterestsModal(true);
    };

    const handleNewActionDate = (event) => {
        setNewActionDate(event);
    };

    const handleNewActionFile = async (event) => {
        const file = event.target.files[0]
        setNewActionFile(file)
    }

    const handlePreAgreementLawer = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handlePreAgreementDebtor = (event) => {
        setSelectedDebtor(event.target.value)
    };

    const handleNewActionUser = (event) => {
        setSelectedLawer(event.target.value)
    };

    const handleNewActionCategory = (event) => {
        setSelectedActionCategory(event.target.value)
    };

    const handleNewTaskUser = (event) => {
        setNewTaskUser(event.target.value)
    };

    const handleNewTaskReminder = (event) => {
        setNewTaskReminder(event.target.value)
    };

    const handleNewTaskDate = (event) => {
        setNewTaskDate(event);
    };

    const handleNewTaskNotification = (event) => {
        setNewTaskNotificationChecked(event)
    };

    const handleNewPaymentDate = (event) => {
        setNewPaymentDate(event);
    };

    const handleNewDebtDetailDate = (event) => {
        calcDebtDetail('date', event);
        setNewDebtDetailDate(event);
    };

    const handleNewDebtDetailAmount = (event) => {
        calcDebtDetail('amount', parseFloat(event.target.value.replace('/,/g', '.')));
    };

    const handlePaymentData = (event, type) => {
        const data = event.target.value;
        let a;
        let f;

        if (type === "amount") {
            if (document.getElementById('newPaymentFee').value.length > 0 && document.getElementById('newPaymentAmount').value.length > 0) {
                a = parseFloat(data);
                f = parseFloat(document.getElementById('newPaymentFee').value)
                document.getElementById('newPaymentCustomer').value = a - (a * f / 100)
                document.getElementById('newPaymentStudy').value = a * f / 100
            }
        } else if (type === "fee") {
            if (document.getElementById('newPaymentFee').value.length > 0 && document.getElementById('newPaymentAmount').value.length > 0) {
                f = parseFloat(data);
                a = parseFloat(document.getElementById('newPaymentAmount').value)
                document.getElementById('newPaymentCustomer').value = a - (a * f / 100)
                document.getElementById('newPaymentStudy').value = a * f / 100
            }
        }

    }

    const handleAgreementDate = (i, d) => {
        let dd = dueDates;
        let nd = [];

        dd[i].date = d

        for (let n of dd) {
            nd.push(n)
        }

        setDueDates(nd)
    };

    const [editActionId, setEditActionId] = React.useState(null);
    const [editActionDate, setEditActionDate] = React.useState(new Date());
    const [editActionUser, setEditActionUser] = React.useState("0");
    const [editActionCategory, setEditActionCategory] = React.useState("0");
    const [editActionDescription, setEditActionDescription] = React.useState("");
    const [editActionFoja, setEditActionFoja] = React.useState("");
    const [editActionFile, setEditActionFile] = React.useState(null);
    const [editActionFileName, setEditActionFileName] = React.useState("");
    const [editActionFileChanged, setEditActionFileChanged] = React.useState(false);
    const [showEditActionModal, setShowEditActionModal] = React.useState(false);
    const handleCloseEditActionModal = () => { setShowEditActionModal(false); };
    const handleShowEditActionModal = (data) => {
        setEditActionId(data.id)
        setEditActionDate(new Date(data.date));
        setEditActionUser(data.userId);
        setEditActionCategory(data.categoryId);
        setEditActionDescription(data.description);
        setEditActionFoja(data.foja);
        setEditActionFileChanged(false);
        if (data.fileId == null) {
            setEditActionFile(null)
            setEditActionFileName(null)
        } else {
            setEditActionFile(null)
            setEditActionFileName(
                <Row>
                    <Col>
                        <Alert className='m-0' variant='secondary'>{data.file.name}</Alert>
                    </Col>
                    <Col xs={2} className='align-center-vertically'>
                        <Button variant='danger' onClick={deleteEditAction}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                    </Col>
                </Row>
            )
        }

        setError()

        setShowEditActionModal(true);
    };

    const deleteEditAction = () => {
        setEditActionFileChanged(true)
        setEditActionFileName(null)
    }

    const handleEditActionFile = async (event) => {
        const file = event.target.files[0]
        setEditActionFile(file)
        setEditActionFileChanged(true)
        setEditActionFileName(
            <Row>
                <Col>
                    <Alert className='m-0' variant='secondary'>{file.name}</Alert>
                </Col>
                <Col xs={2} className='align-center-vertically'>
                    <Button variant='danger' onClick={deleteEditAction}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                </Col>
            </Row>
        )
    }

    const handleEditActionDate = (event) => {
        setEditActionDate(event);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto:100,300,400']
            }
        });
        registerLocale("es", { ...es, options: { ...es.options, weekStartsOn: 0 } });
        document.title = `EAC - ${PAGE_TITLE}`;

        getData();
    }, []);

    const getData = async () => {
        getDebtById(getProceedingId());
        getTasks();
        getReminderOptions();
        getLawers();
        getActionCategories();
    }

    const getProceedingId = function () {
        const params = new URLSearchParams(window.location.search);
        if (params.has('id')) {
            return params.get('id');
        } else {
            return -1;
        }
    }

    const getDebtById = async function (id) {
        const req = await get(`/proceedings/debts/byId/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setDebt(res.Debt);

            setDebtStatus(<Badge bg={res.Debt.Status.color} className='content-table status'>{res.Debt.Status.name}</Badge>)

            if (res.ProceedingCustomer.Business) {
                if (res.ProceedingCustomer.Business.code) {
                    res.ProceedingCustomer.Business.codeBadge = <Badge bg='' className='ms-1 ' style={{ backgroundColor: `${res.ProceedingCustomer.Business.color}` }}>{res.ProceedingCustomer.Business.code}</Badge>;
                }
            }
            setCustomer(res.ProceedingCustomer)
            getCustomerContacts(res.ProceedingCustomer.businessId);

            document.title = `EAC - ${PAGE_TITLE} ${res.Debt.name}`;
            setAgreementColumns(['#', 'Fecha de pago', 'Capital', 'Intereses', 'Honorarios', 'Total']);
            setDueColumns(['#', 'Capital', 'Intereses', 'Honorarios', 'Total']);
            let dues = [];

            setDues(dues);

            setIsArchived(res.isArchived);

            setDebtDetailsColumns(['Alumno', 'Fecha', 'Importe', 'Días', 'Intereses', 'Total', 'Referencia', '']);
            let debtDetails = [];
            for (let dd of res.Debt.DebtDetails) {
                dd.studentName = `${dd.student}`
                if (getUserRole() != "Cliente") {
                    dd.action = (
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowEditDebtDetailModal(dd)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowDeleteDebtDetailModal(dd.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }

                debtDetails.push(dd);
            }
            setDebtDetails(debtDetails);

            setProceedingActionsColumns(['Fecha', 'Categoría', 'Tarea', 'Foja', '', 'Encargado', '']);
            let pa = [];
            for (let dad of res.Actions) {
                if (getUserRole() != "Cliente") {
                    dad.action = (
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {dad.file != null ? <Dropdown.Item onClick={() => downloadFile(dad.file.id, dad.file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}
                                <Dropdown.Item onClick={() => handleShowEditActionModal(dad)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleShowDeleteActionModal(dad.id, dad.description)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                } else {
                    if (dad.file) {
                        dad.action = (
                            <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dad.file != null ? <Dropdown.Item onClick={() => downloadFile(dad.file.id, dad.file.name)}><FontAwesomeIcon icon={faDownload} style={{ marginRight: 8, marginLeft: 2 }} />Descargar archivo</Dropdown.Item> : <div></div>}

                                </Dropdown.Menu>
                            </Dropdown>
                        )
                    }

                }

                dad.fileIcon = dad.file != null ? (
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="button-tooltip-2">{dad.file.name}</Tooltip>}>

                        <span><FontAwesomeIcon style={{ fontSize: 30 }} icon={getFileIcon(dad.file.url)}></FontAwesomeIcon></span>
                    </OverlayTrigger>

                ) : <div></div>
                if (dad.actionCategory) {
                    dad.category = <Badge bg='' className='content-table status' style={{ backgroundColor: `${dad.actionCategory.color}` }}>{dad.actionCategory.name}</Badge>;
                } else {
                    dad.category = <div></div>
                }

                dad.createdBy = `${dad.User.name} ${(dad.User.surname).substring(0, 1)}.`;
                pa.push(dad);
            }
            setProceedingActions(pa);

            let defs = [];
            for (let def of res.ProceedingDefendants) {
                if (def.businessId == null && def.personId == null) {

                } else {
                    defs.push(def)
                }
            }
            setDefendants(defs)

            let s = [];
            for (let st of res.Debt.Students) {
                s.push(st.Person)
            }
            setStudents(s)

            setPaymentsColumns(['Fecha', 'Pago', 'Cliente', 'Estudio', 'Liquidado', ''])
            let p = [];
            for (let pa of res.Payments) {
                if (pa.executed) {
                    pa.exe = <Badge className='' bg='success'>Si</Badge>;
                    pa.exeDateFormatted = moment(new Date(pa.exeDate)).format("DD/MM/YYYY");
                } else {
                    pa.exe = <Badge className='' bg='danger'>No</Badge>;
                    pa.exeDateFormatted = "";
                }

                if (getUserRole() != "Cliente") {
                    pa.action = (
                        <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleShowDeletePaymentModal(pa.id)}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    )
                }

                p.push(pa)
            }
            setPayments(p)
            //setCanPayOff(res.canPayOff)
            setPendingPayOff(res.pendingPayOff)

        } else {
            alert(res.message);
        }
    }

    const checkEditAction = () => {
        let result = true;

        if (document.getElementById('editActionCategory').value == '0') {
            result = false;
            showError('No se ha seleccionado una categoría')
        } else if (document.getElementById("editActionDescription").value.length <= 1) {
            result = false;
            showError('No se ha escrito una descripción')
        }

        return result;
    }

    const editAction = async function () {
        if (checkEditAction()) {
            let file = null;
            let fileName = null;
            let fileExtension = null;

            let body = {
                proceedingId: getProceedingId(),
                id: editActionId,
                userId: parseInt(document.getElementById('editActionUser').value),
                categoryId: document.getElementById('editActionCategory').value == "0" ? null : parseInt(document.getElementById('editActionCategory').value),
                description: document.getElementById('editActionDescription').value,
                foja: document.getElementById('editActionFoja').value,
                date: editActionDate
            };

            if (editActionFileChanged) {
                if (editActionFile != null) {
                    file = await convert2Base64(editActionFile);
                    const aux = editActionFile.name.split('.')
                    fileExtension = `.${aux[aux.length - 1]}`
                    fileName = editActionFile.name;

                    body.file = file;
                    body.fileName = fileName;
                    body.fileExtension = fileExtension;
                } else {
                    body.file = null;
                }
            }

            const req = await put(`/proceedings/actions/`, getUserToken(), body);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseEditActionModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }
        }
    }

    const processContacts = function (data) {
        let result = [];
        let aux;
        let icon;

        for (let c of data) {
            aux = null;

            for (let i = 0; i < c.ContactData.length; i++) {
                switch (c.ContactData[i].param) {
                    case "PHON":
                        icon = <FontAwesomeIcon icon={faPhoneAlt}></FontAwesomeIcon>;
                        break;
                    case "EMAI":
                        icon = <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>;
                        break;
                    case "ADDR":
                        icon = <FontAwesomeIcon icon={faMapMarkerAlt}></FontAwesomeIcon>;
                        break;
                    case "POSI":
                        icon = <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>;
                        break;
                    case "DNIN":
                        icon = <FontAwesomeIcon icon={faIdCard}></FontAwesomeIcon>;
                        break;
                    case "OTHE":
                        icon = <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>;
                        break;
                    default:
                        break;
                }

                if (i === 0) {
                    aux = <span>{icon} {c.ContactData[i].data}</span>;
                } else {
                    aux = <span>{aux} | {icon} {c.ContactData[i].data}</span>;
                }
            }
            c.data = aux;
            result.push(c);
        }
        setCustomerContacts(result);
    }

    const getCustomerContacts = async function (id) {
        const req = await get(`/customers/business/contacts/${id}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            processContacts(res)
        } else {
            alert(res.message);
        }
    }

    const getLawers = async function () {
        const req = await get(`/users/lawers`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setLawers(res)
        } else {
            alert(res.message);
        }
    }

    const getReminderOptions = async function () {
        const req = await get(`/tasks/reminderOptions/`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setReminderOptions(res)
        } else {
            alert(res.message);
        }
    }

    const getTasks = async function () {
        const req = await get(`/tasks/byProceedingId/${getProceedingId()}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            console.log(res)
            setTasks(res)
        } else {
            alert(res.message);
        }
    }

    const getActionCategories = async function () {
        const req = await get(`/proceedings/actions/categories`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setActionCategories(res)
        } else {
            alert(res.message);
        }
    }

    const getAllDebtStatus = async function () {
        const req = await get(`/status/debts`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            setDebtStatuses(res)
        } else {
            alert(res.message);
        }
    }

    const setTaskAsCompleted = async function (taskId) {
        const req = await put(`/tasks/byProceedingId/asCompleted/`, getUserToken(), { taskId: taskId, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            getTasks();
        } else {
            alert(res.message);
        }
    }

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const createPreAgreement = async function () {
        setPreAgreementButton(<span><Spinner animation="grow" variant="light" size="sm" /><Spinner animation="grow" variant="light" size="sm" className='ms-1' /><Spinner animation="grow" variant="light" size="sm" className='ms-1' /></span>)

        const pa = {
            contactId: selectedCustomerContacts.id,
            lawerId: selectedLawer,
            debtorId: selectedDebtor,
            proceedingId: getProceedingId(),
            date: preAgreementDate
        };

        const req = await post(`/proceedings/debts/preagreement/`, getUserToken(), pa);
        const res = await req.json();

        if (req.status === 201) {
            await delay(500);
            triggerBase64Download(`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${res.data}`, 'Pre Acuerdo');
            await delay(500);
            handleClosePreAgreementModal();
        } else {
            alert(res.message);
            setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
        }
    }

    const createAgreement = async function () {
        let qty = document.getElementById('textDueQty').value;
        if (qty.length > 0) {
            qty = parseInt(qty);

            let data = {
                proceedingId: getProceedingId(),
                name: "Prueba",
                contactId: parseInt(document.getElementById('selectAgreementContact').value),
                lawerId: parseInt(document.getElementById('selectAgreementLawer').value),
                debtorId: parseInt(document.getElementById('selectAgreementDefendant').value)
            };
            data.dues = {};

            for (let i = 0; i < qty; i++) {
                data.dues[i + 1] = {
                    number: i + 1,
                    date: dueDates[i] ? dueDates[i].date : null,
                    amount: parseFloat(document.getElementById(`textDueAmount${i}`).value).toFixed(2),
                    interests: parseFloat(document.getElementById(`textDueInterests${i}`).value).toFixed(2),
                    fee: parseFloat(document.getElementById(`textDueFee${i}`).value).toFixed(2),
                    total: parseFloat(document.getElementById(`textDueTotal${i}`).value).toFixed(2),
                }
            }

            const req = await post(`/proceedings/debts/agreement/`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                const fileName = `Acuerdo - ${debt.name} - ${customer.Business.code}`
                await delay(500);
                triggerBase64Download(res.file, fileName);
                await delay(500);
                handleCloseAgreementModal();
                getData();
            } else {
                alert(res.message);
                setPreAgreementButton(<span><FontAwesomeIcon icon={faDownload} className='me-1'></FontAwesomeIcon>Descargar</span>)
            }
        }
    }

    const createAction = async function () {
        if (checkNewAction()) {
            let file = null;
            let fileName = null;
            let fileExtension = null;

            if (newActionFile != null) {
                file = await convert2Base64(newActionFile);
                const aux = newActionFile.name.split('.')
                fileExtension = `.${aux[aux.length - 1]}`
                fileName = newActionFile.name;
            }

            const ac = {
                proceedingId: getProceedingId(),
                userId: selectedLawer,
                categoryId: selectedActionCategory,
                file: file,
                fileName: fileName,
                fileExtension: fileExtension,
                description: document.getElementById('newActionDescription').value,
                foja: document.getElementById('newActionFoja').value,
                date: newActionDate
            };

            const req = await post(`/proceedings/actions/`, getUserToken(), ac);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseNewActionModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkNewAction = () => {
        let result = true;

        if (selectedLawer == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (selectedActionCategory == '0') {
            result = false;
            showError('No se ha seleccionado una categoría')
        } else if (document.getElementById("newActionDescription").value.length <= 1) {
            result = false;
            showError('No se ha escrito una descripción')
        }

        return result;
    }

    const createTask = async function () {
        const task = {
            proceedingId: getProceedingId(),
            userId: newTaskUser,
            dueDate: newTaskDate,
            alertId: newTaskReminder,
            title: document.getElementById("newTaskTitle").value,
            description: document.getElementById("newTaskDescription").value,
            isEvent: true
        };

        const req = await post(`/tasks/`, getUserToken(), task);
        const res = await req.json();

        if (req.status === 201) {
            getTasks();
            handleCloseNewTaskModal();
        } else {
            alert(res.message);
        }
    }

    const showError = (title) => {
        setError(
            <Alert className='mt-3 mb-0' key='danger' variant='danger'>
                {title}
            </Alert>
        )
    }

    const downloadFile = async (fileId, name) => {
        const req = await get(`/files/byId/${fileId}`, getUserToken());
        const res = await req.json();

        if (req.status === 200) {
            const linkSource = res.file;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = name;
            downloadLink.click();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const deleteAction = async function () {
        const data = {
            proceedingId: getProceedingId(),
            id: selectedActionId
        }
        const req = await del(`/proceedings/actions/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getDebtById(getProceedingId())
            handleCloseDeleteActionModal()
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const handleDues = (textId) => {
        let qty = document.getElementById('textDueQty').value;
        if (qty.length > 0) {
            qty = parseInt(qty);

            let d = [];
            let auxAmount;
            let auxInterests;
            let auxFee;
            let auxTotal;

            let leftAmount = parseFloat(debt.capital);
            let leftInterests = parseFloat(debt.interests);
            let leftFee = parseFloat(debt.totalFee);
            let leftTotal = parseFloat(debt.totalDebt)

            for (let i = 0; i < qty; i++) {
                auxAmount = parseFloat(document.getElementById(`textDueAmount${i}`).value).toFixed(2);
                auxInterests = parseFloat(document.getElementById(`textDueInterests${i}`).value).toFixed(2);
                auxFee = parseFloat(document.getElementById(`textDueFee${i}`).value).toFixed(2);
                auxTotal = 0;

                if (!isNaN(auxAmount)) {
                    if (auxAmount.length > 0) {
                        auxTotal += parseFloat(auxAmount);
                        leftAmount -= parseFloat(auxAmount);
                    }
                }

                if (!isNaN(auxFee)) {
                    if (auxFee.length > 0) {
                        auxTotal += parseFloat(auxFee);
                        leftFee -= parseFloat(auxFee);
                    }
                }

                if (!isNaN(auxInterests)) {
                    if (auxInterests.length > 0) {
                        auxTotal += parseFloat(auxInterests);
                        leftInterests -= parseFloat(auxInterests);
                    }
                }


                document.getElementById(`textDueTotal${i}`).value = auxTotal.toFixed(2);

                leftTotal -= auxTotal;

                d.push({
                    dueNumber: i + 1,
                    amount: auxAmount,
                    fee: auxFee,
                    interests: auxInterests,
                    total: auxTotal
                })

                if (!isNaN(auxAmount) && !isNaN(auxFee) && !isNaN(auxInterests)) {

                }

            }

            document.getElementById('textDueAmountLeft').value = pn(leftAmount.toFixed(2) == "-0.00" ? "0.00" : leftAmount.toFixed(2));
            document.getElementById('textDueInterestsLeft').value = pn(leftInterests.toFixed(2) == "-0.00" ? "0.00" : leftInterests.toFixed(2));
            document.getElementById('textDueFeeLeft').value = pn(leftFee.toFixed(2) == "-0.00" ? "0.00" : leftFee.toFixed(2));
            document.getElementById('textDueTotalLeft').value = pn(leftTotal.toFixed(2) == "-0.00" ? "0.00" : leftTotal.toFixed(2));

            if (parseFloat(document.getElementById('textDueAmountLeft').value) < 0) {
                const e = document.getElementById('textDueAmountLeft');
                e.classList.add('text-error')
            } else {
                const e = document.getElementById('textDueAmountLeft');
                e.classList.remove('text-error')
            }

            if (parseFloat(document.getElementById('textDueInterestsLeft').value) < 0) {
                const e = document.getElementById('textDueInterestsLeft');
                e.classList.add('text-error')
            } else {
                const e = document.getElementById('textDueInterestsLeft');
                e.classList.remove('text-error')
            }

            if (parseFloat(document.getElementById('textDueFeeLeft').value) < 0) {
                const e = document.getElementById('textDueFeeLeft');
                e.classList.add('text-error')
            } else {
                const e = document.getElementById('textDueFeeLeft');
                e.classList.remove('text-error')
            }
        }
    };


    const calculateDues = () => {
        let qty = document.getElementById('textDueQty').value;
        if (qty.length > 0) {
            qty = parseInt(qty);
            const amount = parseFloat(debt.capital) / qty;
            const interests = parseFloat(debt.interests) / qty;
            const fee = parseFloat(debt.totalFee) / qty;
            const total = parseFloat(debt.totalDebt) / qty;

            let leftAmount = parseFloat(debt.capital);
            let leftInterests = parseFloat(debt.interests);
            let leftFee = parseFloat(debt.totalFee);
            let leftTotal = parseFloat(debt.totalDebt)

            for (let i = 0; i < qty; i++) {
                if (i < qty - 1) {
                    document.getElementById(`textDueAmount${i}`).value = amount.toFixed(2);
                    document.getElementById(`textDueInterests${i}`).value = interests.toFixed(2);
                    document.getElementById(`textDueFee${i}`).value = fee.toFixed(2);
                    document.getElementById(`textDueTotal${i}`).value = total.toFixed(2);

                    leftAmount -= amount.toFixed(2);
                    leftInterests -= interests.toFixed(2);
                    leftFee -= fee.toFixed(2);
                    leftTotal -= total.toFixed(2);
                } else {
                    document.getElementById(`textDueAmount${i}`).value = leftAmount.toFixed(2);
                    document.getElementById(`textDueInterests${i}`).value = leftInterests.toFixed(2);
                    document.getElementById(`textDueFee${i}`).value = leftFee.toFixed(2);
                    document.getElementById(`textDueTotal${i}`).value = leftTotal.toFixed(2);
                }
            }
        }

        handleDues();
    }

    const handleQtyDues = (event) => {
        const qty = parseInt(event.target.value);
        let d = [];
        let dd = [];
        for (let i = 0; i < qty; i++) {
            d.push({
                dueNumber: i + 1
            })
            dd.push({ date: new Date() })
        }
        setDueDates(dd)
        setDues(d);
        handleDues(null);
        resetDueFields();
    };

    const resetDueFields = () => {
        let i = 0;

        while (document.getElementById(`textDueAmount${i}`) != null) {
            document.getElementById(`textDueAmount${i}`).value = "";
            document.getElementById(`textDueInterests${i}`).value = "";
            document.getElementById(`textDueFee${i}`).value = "";
            document.getElementById(`textDueTotal${i}`).value = "";
            i++;
        }

        document.getElementById('textDueAmountLeft').value = "";
        document.getElementById('textDueInterestsLeft').value = "";
        document.getElementById('textDueFeeLeft').value = "";
        document.getElementById('textDueTotalLeft').value = "";
    }

    const deleteProceeding = async function () {
        const data = {
            proceedingId: getProceedingId(),
        }
        const req = await del(`/proceedings/`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.href = "/moras"
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const refreshInterests = async function () {
        const data = {
            proceedingId: getProceedingId(),
        }
        const req = await put(`/proceedings/debts/rate`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const changeModule = async function () {
        const req = await put(`/proceedings/module`, getUserToken(), { module: selectedModule, proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 200) {
            if (selectedModule == "Archivo") {
                window.location.href = '/archivo'
            } else if (selectedModule == "Judicial") {
                window.location.href = '/expedientes'
            } else if (selectedModule == "Deuda") {
                window.location.href = '/moras'
            }

        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const createNewPayment = async function () {
        if (checkNewPayment()) {
            const a = parseFloat(document.getElementById('newPaymentAmount').value);
            const c = parseFloat(document.getElementById('newPaymentCustomer').value);
            const s = parseFloat(document.getElementById('newPaymentStudy').value);

            const data = {
                proceedingId: getProceedingId(),
                date: newPaymentDate,
                amount: a,
                customer: c,
                study: s,
                executed: false,
                fileId: null,
                customerId: customer.businessId,
                fee: null,
                income: false,
                description: document.getElementById('newPaymentDescription').value,
                cashFlow: true
            };

            const req = await post(`/administration/payments`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseNewPaymentModal();
            } else {
                alert(res.message);
            }
        }
    }

    const checkNewPayment = () => {
        let result = true;
        const a = parseFloat(document.getElementById('newPaymentAmount').value);
        const f = parseFloat(document.getElementById('newPaymentFee').value);
        const cus = parseFloat(document.getElementById('newPaymentCustomer').value);
        const stu = parseFloat(document.getElementById('newPaymentStudy').value);

        if (a.length == 0) {
            result = false;
            showError('Escriba el monto del pago')
        } else if (f.length == 0) {
            result = false;
            showError('Escriba el porcentaje de honorarios')
        } else if (newPaymentDate === null) {
            result = false;
            showError('No hay una fecha especificada')
        } else if ((cus + stu) != a) {
            result = false;
            showError('La suma de deuda y honorarios debe ser igual al importe pagado')
        }

        return result;
    }

    /*const payOff = async function (taskId) {
        const req = await post(`/administration/payoff`, getUserToken(), { proceedingId: getProceedingId() });
        const res = await req.json();

        if (req.status === 201) {
            getDebtById(getProceedingId());
        } else {
            alert(res.message);
        }
    }*/

    const deletePayment = async () => {
        const req = await del(`/administration/payments`, getUserToken(), { id: selectedPaymentId });
        const res = await req.json();

        if (req.status === 201) {
            window.location.reload();
        } else if (req.status === 403) {
            logout();
        } else {
            alert(res.message);
        }
    }

    const updateTask = async function () {
        if (checkEditTask()) {
            const task = {
                userId: editTaskUser,
                dueDate: editTaskDate,
                alertId: editTaskReminder,
                title: document.getElementById("editTaskTitle").value,
                description: document.getElementById("editTaskDescription").value,
                isEvent: true,
                taskId: editTaskId
            };

            const req = await put(`/tasks/`, getUserToken(), task);
            const res = await req.json();

            if (req.status === 201) {
                getTasks();
                handleCloseEditTaskModal();
            } else if (req.status === 403) {
                logout();
            } else {
                alert(res.message);
            }

            console.log(task)
        }
    }

    const checkEditTask = () => {
        let result = true;

        if (editTaskUser == '0') {
            result = false;
            showError('No se ha asignado un encargado')
        } else if (document.getElementById("editTaskTitle").value.length <= 1) {
            result = false;
            showError('No se ha escrito el título')
        }

        return result;
    }

    const createNewDebtDetail = async function () {
        if (checkNewDebtDetail()) {
            const data = {
                proceedingId: getProceedingId(),
                debtId: debt.id,
                student: document.getElementById('selecteNewDebtDetailStudent').value,
                date: newDebtDetailDate,
                amount: parseFloat(document.getElementById('textNewDebtDetailAmount').value),
                days: parseInt(document.getElementById('textNewDebtDetailDays').value),
                interests: parseInt(document.getElementById('textNewDebtDetailInterests').value),
                total: parseInt(document.getElementById('textNewDebtDetailTotal').value),
                description: document.getElementById('textNewDebtDetailDescription').value
            };

            const req = await post(`/proceedings/debts/detail`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseNewDebtDetailModal();
            } else {
                alert(res.message);
            }
        }
    }

    const editOneDebtDetail = async function () {
        if (checkNewDebtDetail()) {
            const data = {
                debtDetailId: editDebtDetail.id,
                proceedingId: getProceedingId(),
                student: document.getElementById('selecteNewDebtDetailStudent').value,
                date: newDebtDetailDate,
                amount: parseFloat(document.getElementById('textNewDebtDetailAmount').value),
                days: parseInt(document.getElementById('textNewDebtDetailDays').value),
                interests: parseInt(document.getElementById('textNewDebtDetailInterests').value),
                total: parseInt(document.getElementById('textNewDebtDetailTotal').value),
                description: document.getElementById('textNewDebtDetailDescription').value
            };

            const req = await put(`/proceedings/debts/detail`, getUserToken(), data);
            const res = await req.json();

            if (req.status === 201) {
                getDebtById(getProceedingId());
                handleCloseEditDebtDetailModal();
            } else {
                alert(res.message);
            }
        }
    }

    const deleteDebtDetail = async function () {
        const data = {
            id: deleteDebtDetailId,
        };

        const req = await del(`/proceedings/debts/detail`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getDebtById(getProceedingId());
            handleCloseDeleteDebtDetailModal();
        } else {
            alert(res.message);
        }
    }

    const changeStatus = async function () {
        const data = {
            debtId: debt.id,
            statusId: parseInt(document.getElementById('selectChangeStatus').value)
        };

        const req = await put(`/proceedings/debts/status`, getUserToken(), data);
        const res = await req.json();

        if (req.status === 201) {
            getDebtById(getProceedingId());
            handleCloseChangeStatusModal();
        } else {
            alert(res.message);
        }
    }

    const checkNewDebtDetail = () => {
        let result = true;

        if (document.getElementById('selecteNewDebtDetailStudent').value == '0') {
            result = false;
            showError('No se ha asignado un alumno')
        } else if (document.getElementById("textNewDebtDetailAmount").value.length <= 0) {
            result = false;
            showError('No se ha especificado un monto')
        }

        return result;
    }

    const calcDebtDetail = function (dataFrom, value) {
        if (dataFrom === 'date') {
            let a = document.getElementById('textNewDebtDetailAmount').value.replace('/,/g', '.');
            if (!isNaN(a) && a !== '') {
                const days = parseInt((moment.duration(moment().diff(moment(value)))).asDays());
                const interests = ((parseFloat(debt.rate) / 30) * days) * a / 100;
                const total = parseFloat(a) + interests;
                document.getElementById('textNewDebtDetailDays').value = days;
                document.getElementById('textNewDebtDetailInterests').value = interests.toFixed(2);
                document.getElementById('textNewDebtDetailTotal').value = total.toFixed(2);
            } else {
                const days = parseInt((moment.duration(moment().diff(moment(value)))).asDays());
                document.getElementById('textNewDebtDetailDays').value = days;
            }
        } else if (dataFrom === 'amount') {
            if (newDebtDetailDate !== '') {
                const days = parseInt((moment.duration(moment().diff(moment(newDebtDetailDate)))).asDays());
                const interests = ((parseFloat(debt.rate) / 30) * days) * parseFloat(value) / 100;
                const total = parseFloat(value) + interests;
                document.getElementById('textNewDebtDetailDays').value = days;
                document.getElementById('textNewDebtDetailInterests').value = interests.toFixed(2);
                document.getElementById('textNewDebtDetailTotal').value = total.toFixed(2);
            }
        }
    }

    return (
        <div className='p-0' style={{ fontFamily: 'Roboto' }}>
            <style>{'body {background-color: #EBEBEB !important;}'}</style>
            <NavBar />

            <div style={{ height: 300, background: '#FAFAFA' }}>
                <Container>
                    <Row style={{ paddingTop: 10 }}>
                        <table className='w-75' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                            <tr>
                                <td>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 25, fontWeight: 300 }}>EXP-GDD #{debt.id}</p>
                                    <p className='m-0' style={{ color: '#A0A0A0', fontSize: 45, fontWeight: 300 }}>{debt.name}</p>
                                </td>
                                <td style={{ fontSize: 140, width: 150 }}>
                                    <FontAwesomeIcon icon={PAGE_ICON} style={{ fontSize: 140, color: "A0A0A0" }} />
                                </td>
                            </tr>
                        </table>
                    </Row>
                </Container>
            </div>

            <Container>
                <Row style={{ marginTop: -70 }}>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    {getUserRole() != "Cliente" ?
                                        <Link to={'/moras'} className='link-primary'>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Gestión de deudas
                                        </Link>
                                        :
                                        <Link to={'/expedientes'} className='link-primary'>
                                            <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: 8 }} />Volver a Gestión de expedientes
                                        </Link>
                                    }

                                    <span className='ms-2'>|</span><span className='ms-2 me-2'>{debtStatus}</span>
                                    {getUserRole() != "Cliente" ?
                                        <span>|<span className='ms-2'>{debt.User ? <img className='avatar-30 me-2' style={{ objectFit: 'cover' }} src={`${params().dataUri}${debt.User.avatar}`}></img> : ""}{debt.User ? `${debt.User.name} ${debt.User.surname}` : ""}</span></span>
                                        :
                                        <div></div>
                                    }

                                </td>
                                <td className='content-options rows right'>
                                    {getUserRole() != "Cliente" ?
                                        <Dropdown>
                                            <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                                <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={handleShowPreAgreementModal}><FontAwesomeIcon icon={faFileWord} style={{ marginRight: 11, marginLeft: 3 }} />Generar pre-acuerdo</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowAgreementModal}><FontAwesomeIcon icon={faFile} style={{ marginRight: 11, marginLeft: 3 }} />Generar acuerdo</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowCalcDuesModal}><FontAwesomeIcon icon={faWallet} style={{ marginRight: 8, marginLeft: 3 }} />Simulador de cuotas</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowConfirmUpdateInterestsModal}><FontAwesomeIcon icon={faRefresh} style={{ marginRight: 8, marginLeft: 2 }} />Actualizar intereses</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowChangeStatusModal}><FontAwesomeIcon icon={faPen} style={{ marginRight: 8, marginLeft: 2 }} />Modificar estado</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowChangeModuleModal}><FontAwesomeIcon icon={faShare} style={{ marginRight: 8, marginLeft: 2 }} />Mover de módulo</Dropdown.Item>
                                                <Dropdown.Item onClick={handleShowDeleteProceedingModal}><FontAwesomeIcon icon={faTrash} style={{ marginRight: 10, marginLeft: 2 }} />Eliminar</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :
                                        <div></div>
                                    }

                                </td>
                            </tr>
                        </table>

                        <Container>
                            <Row>
                                {customer ?
                                    <Container>
                                        <Accordion className='p-0 mt-3' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p className='m-0'>
                                                        Datos del cliente: <span>{customer.Person != null ? `${customer.Person.surname.toUpperCase()}, ${customer.Person.name}` : <span>{customer.Business.legalName} {customer.Business.codeBadge}</span>}</span>
                                                    </p>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <p className='column-title'>Datos del cliente</p>
                                                        </Col>
                                                        <Col xs={6}>
                                                            {customer.Business ?
                                                                <p className='column-title'>Contactos</p>
                                                                :
                                                                <div></div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className='mb-3' style={{ marginTop: -16 }}>

                                                        <Col xs={6}>
                                                            {customer.businessId == null ?
                                                                //Its a person
                                                                <div className='column-content'>
                                                                    <table>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                            <td className='ps-5'>{customer.Person != null ? customer.Person.surname.toUpperCase() : ""}, {customer.Person.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                            <td className='ps-5'>{customer.Person.dni}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                            <td className='ps-5'>{customer.Person.phone}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                            <td className='ps-5'>{customer.Person.mobile}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                            <td className='ps-5'>{customer.Person.email}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                            <td className='ps-5'>{customer.Person.isWorking ? `Si - ${customer.Person.placeWork}` : 'No'}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                            <td className='ps-5'>{customer.Person.address}<br></br>{customer.Person.city != null ? `${customer.Person.city}` : ''}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                                :
                                                                //Its a business
                                                                <div className='column-content'>
                                                                    <table>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                            <td className='ps-5'>{customer.Business.legalName}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                            <td className='ps-5'>{customer.Business.cuit}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                            <td className='ps-5'>{customer.Business.educationalNetwork != null ? customer.Business.educationalNetwork.name : "---"}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                            <td className='ps-5'>{customer.Business.address}<br></br>{customer.Business.city != null ? `${customer.Business.city}` : ''}</td>
                                                                        </tr>
                                                                    </table>
                                                                </div>
                                                            }
                                                        </Col>
                                                        <Col xs={6}>
                                                            {customer.Business != null ?
                                                                <div className='column-content'>
                                                                    {customerContacts.length > 0 ?
                                                                        <Table className=''>

                                                                            <tbody>
                                                                                {customerContacts.map((r, i) => (
                                                                                    <tr key={i} >
                                                                                        <td className='content-table rows align-middle ps-4'>{r.name}</td>
                                                                                        <td className='content-table rows align-middle'>{r.data}</td>
                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </Table>

                                                                        :
                                                                        <Container className='mt-4 mb-4'>
                                                                            <Row>
                                                                                <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                                                    <FontAwesomeIcon icon={faAddressBook} />
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col style={{ textAlign: 'center' }}>
                                                                                    No hay contactos
                                                                                </Col>
                                                                            </Row>
                                                                        </Container>
                                                                    }
                                                                </div>
                                                                :
                                                                <div></div>
                                                            }
                                                        </Col>

                                                    </Row>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Container>
                                    :
                                    <div></div>

                                }

                            </Row>
                            <Row>
                                {defendants.length > 0 ?
                                    <Container>
                                        <Accordion className='p-0 mt-3' >
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <p className='m-0'>
                                                        Datos del contrario/s: {defendants.map((d, i) => (<span>{d.Person != null ? `${d.Person.surname.toUpperCase()}, ${d.Person.name}${defendants.length > (i + 1) ? "; " : ""}` : `${d.Business.legalName}${defendants.length > (i + 1) ? "; " : ""}`}</span>))}
                                                    </p>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <Row>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                <p className='column-title'>Contrario {i + 1}</p>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                    <Row className='mb-3' style={{ marginTop: -16 }}>
                                                        {defendants.map((d, i) => (
                                                            <Col xs={4}>
                                                                {d.businessId == null ?
                                                                    //Its a person
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Person != null ? d.Person.surname.toUpperCase() : ""}, {d.Person.name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>DNI</td>
                                                                                <td className='ps-5'>{d.Person.dni}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Teléfono</td>
                                                                                <td className='ps-5'>{d.Person.phone}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Celular</td>
                                                                                <td className='ps-5'>{d.Person.mobile}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Correo</td>
                                                                                <td className='ps-5'>{d.Person.email}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Trabajo</td>
                                                                                <td className='ps-5'>{d.Person.isWorking ? `Si - ${d.Person.placeWork}` : 'No'}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Person.address}<br></br>{d.Person.city != null ? `${d.Person.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                    :
                                                                    //Its a business
                                                                    <div className='column-content'>
                                                                        <table>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Nombre</td>
                                                                                <td className='ps-5'>{d.Business.legalName}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>CUIT</td>
                                                                                <td className='ps-5'>{d.Business.cuit}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Orden</td>
                                                                                <td className='ps-5'>{d.Business.educationalNetwork != null ? d.Business.educationalNetwork.name : "---"}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td style={{ fontWeight: 'bold' }}>Domicilio</td>
                                                                                <td className='ps-5'>{d.Business.address}<br></br>{d.Business.city != null ? `${d.Business.city}` : ''}</td>
                                                                            </tr>
                                                                        </table>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        ))}
                                                    </Row>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Container>
                                    :
                                    <div></div>

                                }

                            </Row>

                            <Row className='mt-4 mb-0'>
                                <Col>
                                    <p className='column-title'>Datos de la deuda</p>
                                </Col>
                                <Col>
                                    <p className='column-title'>Alumno/s</p>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                            <Row className='mb-4' style={{ marginTop: -16 }}>
                                <Col>
                                    <div className='column-content-nh mb-2'>
                                        <table>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Capital</td>
                                                <td className='ps-5'>${pn(debt.capital)}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Intereses</td>
                                                <td className='ps-5'>${pn(debt.interests)} ({debt.rate}%)</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Honorarios</td>
                                                <td className='ps-5'>${pn(debt.totalFee)} ({debt.fee}%)</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontWeight: 'bold' }}>Total deuda</td>
                                                <td className='ps-5'><Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.totalDebt)}</Badge></td>
                                            </tr>

                                        </table>
                                    </div>


                                </Col>
                                <Col>
                                    <div className='column-content-nh mb-2'>
                                        <Form>
                                            <Form.Group>
                                                {students.map((s, i) => (
                                                    <Form.Control className={i > 0 ? 'mt-2' : ''} disabled type="text" id="" placeholder="" value={`${s.dni ? `DNI ${s.dni} - ` : ""}${s.surname.toUpperCase()}, ${s.name}`} />
                                                ))}
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </Col>
                                <Col>

                                </Col>
                            </Row>
                        </Container>
                    </Card>
                </Row>

                {getUserRole() != "Cliente" ?
                    <div>
                        {isArchived ?
                            <div></div>
                            :
                            <Row>
                                <Card className='cards content mb-5'>
                                    <table className='content-options content'>
                                        <tr>
                                            <td className='content-options rows left'>
                                                Tareas
                                            </td>
                                            <td className='content-options rows right'>
                                                <Button variant="success" onClick={handleShowNewTaskModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva tarea</Button>
                                            </td>
                                        </tr>
                                    </table>

                                    <Container className='mt-3 mb-3'>
                                        {tasks.length > 0 ?
                                            tasks.map((t, i) => (
                                                <div className='file element mb-2' >
                                                    <table>
                                                        <tr>
                                                            <td className='w-100'>
                                                                <p className='m-0'>{t.title}</p>
                                                                <p className='m-0' style={{ fontSize: 12 }}>{t.description}</p>
                                                                <p className='mt-1 mb-0'>{timeDueWithBadge(t.dueDate)}</p>
                                                                <p className='mt-2 mb-0' style={{ fontSize: 12 }}><img className='me-1 avatar-20' style={{ objectFit: 'cover' }} src={`${params().dataUri}${t.User.avatar}`} alt=""></img> <span style={{ marginTop: 'auto', marginBottom: 'auto', height: 20, verticalAlign: 'middle' }}>{t.User.name} {t.User.surname}</span></p>
                                                            </td>
                                                            <td className='w-100'>
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                                                                        <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faEllipsisV} /></span>
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item onClick={() => handleShowEditTaskModal(t)}><FontAwesomeIcon icon={faPen} style={{ marginRight: 7, marginLeft: 2 }} />Modificar</Dropdown.Item>
                                                                        <Dropdown.Item onClick={() => setTaskAsCompleted(t.id)}><FontAwesomeIcon icon={faCheck} style={{ marginRight: 10, marginLeft: 2 }} />Marcar como completado</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            ))
                                            :
                                            <Container className='mt-4 mb-4'>
                                                <Row>
                                                    <Col style={{ textAlign: 'center', fontSize: 40 }}>
                                                        <FontAwesomeIcon icon={faTasks} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: 'center' }}>
                                                        No hay tareas pendientes
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                    </Container>

                                </Card>
                            </Row>
                        }
                    </div>
                    :
                    <div></div>
                }




                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Gestión
                                </td>
                                <td className='content-options rows right'>
                                    {isArchived ?
                                        <div></div>
                                        :
                                        <div >
                                            {getUserRole() != "Cliente" ?
                                                <Button variant="success" onClick={handleShowNewActionModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nueva actividad</Button>
                                                :
                                                <div></div>
                                            }

                                        </div>
                                    }
                                </td>
                            </tr>
                        </table>

                        {proceedingActions.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Fecha
                                            </div>
                                        </Col>
                                        <Col sm={4} className='align-center-vertically-v2'>
                                            <div>
                                                Tarea
                                            </div>
                                        </Col>


                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Categoría
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Foja
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Encargado
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {proceedingActions.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM/YY">{r.date}</Moment>
                                                </Col>
                                                <Col xs={4} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.category}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.foja}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'><img className='avatar-30 me-2' style={{ objectFit: 'cover' }} src={`${params().dataUri}${r.User.avatar}`} />{r.createdBy}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.fileIcon}</p>
                                                    </div>
                                                </Col>


                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.action}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCommentAlt}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron items en gestión</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>



                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    <span className='me-1'>Cuenta corriente</span> | <span className='ms-1'>Pendiente de liquidación: {pendingPayOff > 0 ? <Badge className='content-table status' bg='danger'>${pn(pendingPayOff)}</Badge> : <Badge className='content-table status' bg='success'>${pn(pendingPayOff)}</Badge>}</span>
                                </td>
                                <td className='content-options rows right'>
                                    {isArchived ?
                                        <div></div>
                                        :
                                        <div>
                                            {getUserRole() != "Cliente" ?
                                                <div>
                                                    {/*canPayOff ? <Button variant="success" onClick={payOff}><FontAwesomeIcon icon={faCoins} style={{ marginRight: 8 }} />Liquidar</Button> : ""*/}
                                                    <Button className='ms-2' variant="success" onClick={handleShowNewPaymentModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo pago</Button>
                                                </div>
                                                :
                                                <div></div>
                                            }

                                        </div>

                                    }
                                </td>
                            </tr>
                        </table>

                        {payments.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div>
                                                Fecha
                                            </div>
                                        </Col>

                                        <Col sm={3} className='align-center-vertically-v2' >
                                            <div>
                                                Descripción
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Monto
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Deuda
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Honorarios
                                            </div>
                                        </Col>

                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>
                                                Liqui.
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {payments.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.credit ? '#fce8e8' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={1} className='align-center-vertically'>
                                                    <Moment format="DD/MM/YY">{r.date}</Moment>

                                                </Col>
                                                <Col xs={3} className='align-center-vertically-v2'>
                                                    <div>
                                                        <p className='m-0'>{r.description}</p>
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        ${pn(r.amount)}
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        ${pn(r.customer)}
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        ${pn(r.study)}
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>{r.exe}</p>
                                                        <p className='mt-1 mb-0' style={{ fontSize: 12 }}>{r.exeDateFormatted}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={1} className='align-center-vertically-v2' style={{ textAlign: 'right' }}>
                                                    <div style={{ textAlign: 'right' }}>
                                                        {r.action}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron movimientos</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>


                <Row>
                    <Card className='cards content mb-5'>
                        <table className='content-options content'>
                            <tr>
                                <td className='content-options rows left'>
                                    Liquidación
                                </td>
                                <td className='content-options rows right'>
                                    {isArchived ?
                                        <div></div>
                                        :
                                        <div >
                                            {getUserRole() != "Cliente" ?
                                                <div>
                                                    <Button className='ms-2' variant="success" onClick={handleShowNewDebtDetailModal}><FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 8 }} />Nuevo item</Button>
                                                </div>
                                                :
                                                <div></div>
                                            }

                                        </div>
                                    }
                                </td>
                            </tr>
                        </table>

                        {debtDetails.length > 0 ?
                            <Container className='pt-3 mb-3'>
                                <div className='d-none d-sm-block'>
                                    <Row className=' p-2 mb-2' style={{ background: `${colors().brand_blue}`, color: 'white', borderRadius: 8 }}>
                                        <Col sm={3} className='align-center-vertically-v2'>
                                            <div>
                                                Alumno / Referencia
                                            </div>
                                        </Col>

                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Fecha
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Capital
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Intereses
                                            </div>
                                        </Col>
                                        <Col sm={2} className='align-center-vertically-v2' >
                                            <div style={{ textAlign: 'center' }}>
                                                Total
                                            </div>
                                        </Col>
                                        <Col sm={1} className='align-center-vertically-v2'>
                                            <div style={{ textAlign: 'center' }}>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {debtDetails.map((r, i) => (
                                    <div>
                                        <div className='d-none d-sm-block'>
                                            <Row className='p-2 mb-2' style={{ background: r.income ? '#d8eddd' : '#F0F0F0', borderRadius: 8 }}>
                                                <Col xs={3} className='align-center-vertically'>
                                                    <div>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{r.studentName}</p>
                                                        <p className='m-0'>{r.description}</p>

                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'><Moment format="DD/MM/YYYY">{r.date}</Moment></p>
                                                        <p className='m-0' style={{ fontSize: 10 }}>{r.days} días</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>${pn(parseFloat(r.amount).toFixed(2))}</p>
                                                    </div>
                                                </Col>



                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>${pn(parseFloat(r.interests).toFixed(2))}</p>
                                                    </div>
                                                </Col>

                                                <Col xs={2} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <p className='m-0'>${pn(parseFloat(r.total).toFixed(2))}</p>
                                                    </div>
                                                </Col>


                                                <Col xs={1} className='align-center-vertically-v2'>
                                                    <div style={{ textAlign: 'right' }}>
                                                        <p className='m-0'>{r.action}</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>


                                    </div>

                                ))}
                            </Container>
                            :
                            <Container className='mt-5 mb-5'>

                                <Row style={{ height: 300, textAlign: "center" }}>
                                    <p className='' style={{ height: 40, fontSize: 100, color: "#A0A0A0", marginTop: 50 }}><FontAwesomeIcon icon={faCoins}></FontAwesomeIcon></p>
                                    <p className='m-0' style={{ fontSize: 20, color: "#A0A0A0" }}>No se encontraron items en la liquidaión</p>
                                </Row>
                            </Container>
                        }
                    </Card>
                </Row>
            </Container>

            <Modal show={showPreAgreementModal} onHide={handleClosePreAgreementModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Generar pre-acuerdo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Nombre Apoderado legal</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementApoderadoLegalNombre(event)}>
                            <option value="0">Seleccionar...</option>
                            {customerContacts.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>DNI Apoderado legal</Form.Text>
                        <Form.Control type="text" readOnly id="preagreementApoderadoLegalDNI" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Deudor</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementDebtor(event)}>
                            <option value="0">Seleccionar...</option>
                            {defendants.map((p, i) => (
                                <option key={i} id={i} value={p.Person.id}>{p.Person.name} {p.Person.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Abogado</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handlePreAgreementLawer(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha liquidación</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='textDebtDate' dateFormat="dd/MM/yyyy" selected={preAgreementDate} onChange={(d) => handlePreAgreementDate(d)} />
                    </Form.Group>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClosePreAgreementModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createPreAgreement}>{preAgreementButton}</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewActionModal} onHide={handleCloseNewActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva actividad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newActionDate} onChange={(d) => handleNewActionDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Responsable</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionUser(event)}>
                            <option value="0">Seleccionar...</option>
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewActionCategory(event)}>
                            <option value="0">Seleccionar...</option>
                            {actionCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Gestión</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newActionDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Foja</Form.Text>
                        <Form.Control type="text" id="newActionFoja" placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleNewActionFile(event)} type="file" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewActionModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createAction}>Crear actividad</Button>


                </Modal.Footer>
            </Modal>


            <Modal show={showNewTaskModal} onHide={handleCloseNewTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nueva tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col xs={3} style={{ textAlign: 'right' }}>
                                <ToggleButton
                                    className=""
                                    id="newTaskNotification"
                                    type="checkbox"
                                    variant="outline-success"
                                    checked={newTaskNotificationChecked}
                                    value="1"
                                    onChange={(e) => handleNewTaskNotification(e.currentTarget.checked)}
                                >
                                    Notificar
                                </ToggleButton>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="newTaskTitle" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="newTaskDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={newTaskDate} onChange={(d) => handleNewTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleNewTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createTask}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteActionModal} onHide={handleCloseDeleteActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar la gestión "{selectedActionDescription}"?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteActionModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteAction}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteProceedingModal} onHide={handleCloseDeleteProceedingModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el expediente?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteProceedingModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteProceeding}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeletePaymentModal} onHide={handleCloseDeletePaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el pago?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeletePaymentModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deletePayment}>
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirmUpdateInterestsModal} onHide={handleCloseConfirmUpdateInterestsModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar intereses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea actualizar los intereses de la liquidación al día de hoy?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirmUpdateInterestsModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={refreshInterests}>
                        Actualizar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCalcDuesModal} size={"lg"} onHide={handleCloseCalcDuesModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Simulador de cuotas</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form>
                        <Row>
                            <Col>
                                <p className='m-0'>CAPI <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.capital)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>INTE <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.interests)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>HONO <Badge bg="secondary" style={{ fontSize: 14 }}>${pn(debt.totalFee)}</Badge></p>
                            </Col>
                            <Col>
                                <p className='m-0'>TOTAL <Badge bg="primary" style={{ fontSize: 14 }}>${pn(debt.totalDebt)}</Badge></p>
                            </Col>
                        </Row>
                        <table className='w-100 mt-3'>
                            <tr>
                                <td style={{ width: 160 }}>Cantidad de cuotas:</td>
                                <td style={{ width: 100 }}>
                                    <Form.Group>
                                        <Form.Control className='' type="text" id="textDueQty" placeholder="" onChange={(event) => handleQtyDues(event)} />
                                    </Form.Group>
                                </td>
                                <td className='ps-4'>
                                    <Button variant="primary" onClick={calculateDues}>Calcular cuotas</Button>
                                </td>

                            </tr>
                        </table>
                    </Form>

                    <table striped hover className='mb-3 mt-3 w-100'>
                        <thead>
                            <tr>
                                {dueColumns.map((c, i) => (
                                    <th key={i} className='content-table head'>{c}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dues.map((r, i) => (
                                <tr key={i} >
                                    <td className='content-table rows middle align-middle' style={{ width: 140 }}>{r.dueNumber}</td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueAmount${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueInterests${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueFee${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueTotal${i}`} readOnly placeholder="" />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className='content-table rows middle align-middle' style={{ width: 140, background: '#ffdbdb' }}>Quita</td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueAmountLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueInterestsLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueFeeLeft" placeholder="" readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueTotalLeft" readOnly placeholder="" />
                                </td>
                            </tr>
                        </tbody>
                    </table>



                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCalcDuesModal}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAgreementModal} size={"xl"} onHide={handleCloseAgreementModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Generar acuerdo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className=''>
                        <Col>
                            <p className='column-title'>Acuerdo</p>
                        </Col>
                        <Col>
                            <p className='column-title'>Deuda</p>
                        </Col>
                    </Row>
                    <Row className='mb-4 ' style={{ marginTop: -16 }}>
                        <Col className='column-content-nh ms-2 me-4 '>

                            <Form.Group>
                                <Form.Text>Apoderado legal</Form.Text>
                                <Form.Select className='cover content rows element' id="selectAgreementContact">
                                    {customerContacts.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className='mt-2'>
                                <Form.Text>Deudor</Form.Text>
                                <Form.Select className='cover content rows element' id="selectAgreementDefendant">
                                    {defendants.map((p, i) => (
                                        <option key={i} id={i} value={p.Person.id}>{p.Person.name} {p.Person.surname}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className='mt-2'>
                                <Form.Text>Abogado</Form.Text>
                                <Form.Select className='cover content rows element' defaultValue={debt.userId} id="selectAgreementLawer">
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className='mt-2'>
                                <Form.Text>Fecha liquidación</Form.Text>
                                <DatePicker className='form-control cover content rows element' locale="es" id='textDebtDate' dateFormat="dd/MM/yyyy" selected={preAgreementDate} onChange={(d) => handlePreAgreementDate(d)} />
                            </Form.Group>

                        </Col>
                        <Col className='column-content-nh ms-2 me-4 '>
                            <Form.Group className=''>
                                <Form.Text>Capital</Form.Text>
                                <Form.Control type="text" readOnly defaultValue={pn(debt.capital)} />
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Text>Intereses</Form.Text>
                                <Form.Control type="text" readOnly defaultValue={pn(debt.interests)} />
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Text>Honorarios</Form.Text>
                                <Form.Control type="text" readOnly defaultValue={pn(debt.totalFee)} />
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Text>Total</Form.Text>
                                <Form.Control type="text" readOnly defaultValue={pn(debt.totalDebt)} />
                            </Form.Group>
                        </Col>

                    </Row>

                    <Form className='mt-4'>

                        <table className='w-100 mt-3'>
                            <tr>
                                <td style={{ width: 160 }}>Cantidad de cuotas:</td>
                                <td style={{ width: 100 }}>
                                    <Form.Group>
                                        <Form.Control className='' type="text" id="textDueQty" placeholder="" onChange={(event) => handleQtyDues(event)} />
                                    </Form.Group>
                                </td>
                                <td className='ps-4'>
                                    <Button variant="primary" onClick={calculateDues}>Calcular cuotas</Button>
                                </td>

                            </tr>
                        </table>
                    </Form>

                    <table striped hover className='mb-3 mt-3 w-100'>
                        <thead>
                            <tr>
                                {agreementColumns.map((c, i) => (
                                    <th key={i} className='content-table head'>{c}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {dues.map((r, i) => (
                                <tr key={i} >
                                    <td className='content-table rows middle align-middle' style={{ width: 140 }}>{r.dueNumber}</td>
                                    <td className='content-table rows middle align-middle'>
                                        <DatePicker className='form-control cover content rows element' locale="es" id={`textDueDate${i}`} selected={dueDates[i] ? dueDates[i].date : null} dateFormat="dd/MM/yyyy" onChange={(d) => handleAgreementDate(i, d)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueAmount${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueInterests${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueFee${i}`} placeholder="" onChange={(event) => handleDues(i)} />
                                    </td>
                                    <td className='content-table rows middle align-middle'>
                                        <Form.Control type="text" id={`textDueTotal${i}`} readOnly placeholder="" />
                                    </td>
                                </tr>
                            ))}
                            <tr>
                                <td className='content-table rows middle align-middle' style={{ width: 140, background: '#ffdbdb' }}>Quita</td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}></td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueAmountLeft" placeholder="" defaultValue={pn(debt.capital)} readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueInterestsLeft" placeholder="" defaultValue={pn(debt.interests)} readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueFeeLeft" placeholder="" defaultValue={pn(debt.totalFee)} readOnly />
                                </td>
                                <td className='content-table rows middle align-middle' style={{ background: '#ffdbdb' }}>
                                    <Form.Control type="text" id="textDueTotalLeft" readOnly defaultValue={pn(debt.totalDebt)} placeholder="" />
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Form.Text>Al generar el acuerdo, se creará una tarea para cada cuota, y el estado de la deuda pasará a ser Acuerdo.</Form.Text>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCalcDuesModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createAgreement}>
                        Generar acuerdo
                    </Button>



                </Modal.Footer>
            </Modal>

            <Modal show={showChangeModuleModal} onHide={handleCloseChangeModuleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Mover de módulo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Módulo</Form.Text>
                        <Form.Select className='cover content rows element' id="" onChange={(event) => handleSelectedModule(event)}>
                            <option value="0">Seleccionar...</option>
                            {!isArchived ? <option value='Judicial'>Judicial</option> : ""}
                            {!isArchived ? <option value='Archivo'>Archivo</option> : ""}
                            {isArchived ? <option value='Deuda'>Gestión de deudas</option> : ""}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseChangeModuleModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={changeModule}>Mover</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showChangeStatusModal} onHide={handleCloseChangeStatusModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar estado</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Estado</Form.Text>
                        <Form.Select className='cover content rows element' id="selectChangeStatus" >
                            {debtStatuses.map((s, i) => (
                                <option key={i} id={i} value={s.id}>{s.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseChangeStatusModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={changeStatus}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showNewPaymentModal} onHide={handleCloseNewPaymentModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo pago</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group className=''>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newPaymentDate} onChange={(d) => handleNewPaymentDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control type="text" id="newPaymentDescription" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Monto</Form.Text>
                        <Form.Control type="text" id="newPaymentAmount" placeholder="" onChange={(event) => handlePaymentData(event, 'amount')} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Honorarios (%)</Form.Text>
                        <Form.Control type="text" id="newPaymentFee" placeholder="" defaultValue='20' onChange={(event) => handlePaymentData(event, 'fee')} />
                    </Form.Group>

                    <Form.Group className='mt-4'>
                        <Form.Text>Deuda</Form.Text>
                        <Form.Control type="text" id="newPaymentCustomer" defaultValue='0' placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Honorarios</Form.Text>
                        <Form.Control type="text" id="newPaymentStudy" defaultValue='0' placeholder="" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewPaymentModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createNewPayment}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditTaskModal} onHide={handleCloseEditTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' defaultValue={editTaskUser} onChange={(event) => handleEditTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="editTaskTitle" defaultValue={editTaskTitle} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="editTaskDescription" defaultValue={editTaskDescription} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={editTaskDate} onChange={(d) => handleEditTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editTaskReminder} onChange={(event) => handleEditTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateTask}>Modificar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditTaskModal} onHide={handleCloseEditTaskModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar tarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className=''>
                        <Form.Text>Encargado</Form.Text>
                        <Row>
                            <Col>
                                <Form.Select className='cover content rows element' defaultValue={editTaskUser} onChange={(event) => handleEditTaskUser(event)}>
                                    <option value="0">Seleccionar...</option>
                                    {lawers.map((p, i) => (
                                        <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>

                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Título</Form.Text>
                        <Form.Control type="text" id="editTaskTitle" defaultValue={editTaskTitle} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Descripción</Form.Text>
                        <Form.Control as="textarea" rows={4} id="editTaskDescription" defaultValue={editTaskDescription} placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" minDate={new Date()} id='' dateFormat="dd/MM/yyyy" selected={editTaskDate} onChange={(d) => handleEditTaskDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Recordar</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editTaskReminder} onChange={(event) => handleEditTaskReminder(event)}>
                            <option value="0">No recordar</option>
                            {reminderOptions.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewTaskModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={updateTask}>Modificar</Button>


                </Modal.Footer>
            </Modal>



            <Modal show={showNewDebtDetailModal} onHide={handleCloseNewDebtDetailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text>Al crear un nuevo item en la liquidación, se actualizarán los intereses de los items ya cargados.</Form.Text>
                    <Form.Group className='mt-2'>
                        <Form.Text>Alumno</Form.Text>
                        <Form.Select className='cover content rows element' id="selecteNewDebtDetailStudent" >
                            <option value="0">Seleccionar...</option>
                            {students.map((s, i) => (
                                <option key={i} id={i} value={`${s.surname}, ${s.name}`}>{`${s.dni ? `DNI ${s.dni} - ` : ""}${s.surname}, ${s.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newDebtDetailDate} onChange={(d) => handleNewDebtDetailDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Capital</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailAmount" placeholder="" onChange={(event) => handleNewDebtDetailAmount(event)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Días</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailDays" defaultValue={'0'} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Intereses</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailInterests" defaultValue={'0.00'} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Total</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailTotal" defaultValue={'0.00'} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Referencia</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailDescription" placeholder="" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseNewActionModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={createNewDebtDetail}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteDebtDetailModal} onHide={handleCloseDeleteDebtDetailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Desea eliminar el item de la liquidación?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseDeleteDebtDetailModal}>
                        Cerrar
                    </Button>
                    <Button variant="danger" onClick={deleteDebtDetail}>
                        Confirmar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showEditDebtDetailModal} onHide={handleCloseEditDebtDetailModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Text>Al editar un item en la liquidación, se actualizarán los intereses de los items ya cargados.</Form.Text>
                    <Form.Group className='mt-2'>
                        <Form.Text>Alumno</Form.Text>
                        <Form.Select className='cover content rows element' id="selecteNewDebtDetailStudent" defaultValue={editDebtDetail.student} >
                            <option value="0">Seleccionar...</option>
                            {students.map((s, i) => (
                                <option key={i} id={i} value={`${s.surname}, ${s.name}`}>{`${s.dni ? `DNI ${s.dni} - ` : ""}${s.surname}, ${s.name}`}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={newDebtDetailDate} onChange={(d) => handleNewDebtDetailDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Capital</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailAmount" placeholder="" defaultValue={editDebtDetail.amount} onChange={(event) => handleNewDebtDetailAmount(event)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Días</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailDays" defaultValue={editDebtDetail.days} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Intereses</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailInterests" defaultValue={editDebtDetail.interests} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Total</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailTotal" defaultValue={editDebtDetail.total} readOnly placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Referencia</Form.Text>
                        <Form.Control type="text" id="textNewDebtDetailDescription" defaultValue={editDebtDetail.description} placeholder="" />
                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditDebtDetailModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={editOneDebtDetail}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

            <Modal show={showEditActionModal} onHide={handleCloseEditActionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Modificar actividad</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Text>Fecha</Form.Text>
                        <DatePicker className='form-control cover content rows element' locale="es" id='' dateFormat="dd/MM/yyyy" selected={editActionDate} onChange={(d) => handleEditActionDate(d)} />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Responsable</Form.Text>
                        <Form.Select className='cover content rows element' defaultValue={editActionUser} id="editActionUser">
                            {lawers.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name} {p.surname}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Categoría</Form.Text>
                        <Form.Select className='cover content rows element' id="editActionCategory" defaultValue={editActionCategory}>
                            <option value="0">Seleccionar...</option>
                            {actionCategories.map((p, i) => (
                                <option key={i} id={i} value={p.id}>{p.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className='mt-2'>
                        <Form.Text>Gestión</Form.Text>
                        <Form.Control as="textarea" rows={4} defaultValue={editActionDescription} id="editActionDescription" placeholder="" />
                    </Form.Group>

                    <Form.Group className='mt-2'>
                        <Form.Text>Foja</Form.Text>
                        <Form.Control type="text" id="editActionFoja" defaultValue={editActionFoja} placeholder="" />
                    </Form.Group>

                    <Form.Group className="mt-2">
                        <Form.Text>Archivo</Form.Text>
                        {editActionFileName ?
                            <div>{editActionFileName}</div>
                            :
                            <Form.Control accept='.pdf, .docx, .xlsx, .png, .jpg, .jpeg, .zip' onChange={(event) => handleEditActionFile(event)} type="file" />
                        }

                    </Form.Group>

                    {error}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseEditActionModal}>
                        Cerrar
                    </Button>

                    <Button variant="danger" onClick={editAction}>Confirmar</Button>


                </Modal.Footer>
            </Modal>

        </div>
    )
}